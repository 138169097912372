import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {PagesComponent} from './pages/pages.component';
import {HomeComponent} from './home/home.component';
import {AuthenticatedGuard} from './auth/guards/authenticated/authenticated.guard';

import {ImagingCenterGuard} from './auth/guards/imaging-center/imaging-center.guard';
import {PublicDochealthCheckInModule} from './public-dochealth-check-in/public-dochealth-check-in.module';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Turn Brain Scans into Actionable Insights',
      meta: [
        {
          name: 'description',
          content: 'BrainKey is a personal 3D brain dashboard built by neuroscientists with the goal of empowering individuals and researchers to better understand the brain and improve brain health.'
        }
      ]
    }
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'about',
        loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule),
      },
      {
        path: 'cookies',
        loadChildren: () => import('./pages/cookies/cookies.module').then(m => m.CookiesModule),
      },
      {
        path: 'privacy',
        loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule),
      },
      {
        path: 'account-deletion',
        loadChildren: () => import('./pages/account-deletion/account-deletion.module').then(m => m.AccountDeletionModule),
      },
      {
        path: 'terms-of-service',
        loadChildren: () => import('./pages/terms-of-service/terms-of-service.module').then(
          m => m.TermsOfServiceModule
        ),
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule),
      },
      {
        path: 'publications',
        loadChildren: () => import('./pages/publications/publications.module').then(m => m.PublicationsModule),
      },
      {
        path: 'get-started',
        loadChildren: () => import('./pages/get-started/get-started.module').then(m => m.GetStartedModule),
      },
      {
        path: 'pricing',
        loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule),
      },
      {
        path: 'collaborators',
        loadChildren: () => import('./pages/collaborators/collaborators.module').then(m => m.CollaboratorsModule),
      },
      {
        path: 'how-it-works',
        loadChildren: () => import('./pages/how-it-works/how-it-works.module').then(m => m.HowItWorksModule),
      },
      {
        path: 'video',
        loadChildren: () => import('./pages/brainkey-video/brainkey-video.module').then(m => m.BrainkeyVideoModule),
      },
      {
        path: 'physician-faq',
        loadChildren: () => import('./pages/physician-faq/physician-faq.module').then(m => m.PhysicianFaqModule),
      },
      {
        path: 'scan-quality',
        loadChildren: () => import('./pages/scan-quality/scan-quality.module').then(m => m.ScanQualityModule),
      },
      {
        path: 'locations',
        loadChildren: () => import('./public-locations/public-locations.module').then(m => m.PublicLocationsModule),
      },
      {
        path: 'dochealth',
        loadChildren: () => import('./public-dochealth-check-in/public-dochealth-check-in.module').then(m => m.PublicDochealthCheckInModule),
      },
    ],
  },
  {
    path: 'need-mri',
    loadChildren: () => import('./pages/need-mri/need-mri.module').then(m => m.NeedMriModule),
  },
  {
    path: 'demo',
    loadChildren: () => import('./old-modules/demo/demo.module').then(m => m.DemoModule),
    canActivate: [],
  },
  {
    path: 'settings',
    loadChildren: () => import('./old-modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule),
  },
  {
    path: 'mri-scheduling',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'mri-scheduling/welcome',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'ar-demo',
    loadChildren: () => import('./pages/ar-demo/ar-demo.module').then(m => m.ArDemoModule),
  },
  {
    path: 'ar-experience',
    loadChildren: () => import('./pages/ar-experience/ar-experience.module').then(m => m.ArExperienceModule),
  },
  {
    path: 'rollerblade',
    loadChildren: () => import('./pages/rollerblade/rollerblade.module').then(m => m.RollerbladeModule),
  },
  {
    path: 'print-your-brain',
    loadChildren: () => import('./pages/print-your-brain/print-your-brain.module').then(m => m.PrintYourBrainModule),
  },
  {
    path: 'imaging-center',
    loadChildren: () => import('./old-imaging-center/imaging-center.module').then(m => m.ImagingCenterModule),
    canActivate: [AuthenticatedGuard, ImagingCenterGuard]
  },
  {
    path: 'scan-policies',
    loadChildren: () => import('./pages/scan-policies/scan-policies.module').then(m => m.ScanPoliciesModule),
  },
  {
    path: 'retrieve-mri',
    loadChildren: () => import('./pages/retrieve-mri/retrieve-mri.module').then(m => m.RetrieveMriModule),
  },
  {
    path: 'retrieve-mri',
    pathMatch: 'full',
    redirectTo: ''
  },
  {
    path: 'mri-records',
    loadChildren: () => import('./old-modules/mri-records/mri-records.module').then(m => m.MriRecordsModule),
  },
  {
    path: 'mri',
    loadChildren: () => import('./pages/learn-more/learn-more.module').then(m => m.LearnMoreModule),
  },
  {
    path: 'MRI', redirectTo: '/mri', pathMatch: 'full'
  },
  {
    path: 'backbayimaging',
    loadChildren: () => import('./pages/learn-more/learn-more.module').then(m => m.LearnMoreModule),
  },
  {
    path: 'westernimaging',
    loadChildren: () => import('./pages/learn-more/learn-more.module').then(m => m.LearnMoreModule),
  },
  {
    path: 'simonmed',
    loadChildren: () => import('./pages/simon-med/simon-med.module').then(m => m.SimonMedModule),
  },
  {
    path: 'physicians',
    loadChildren: () => import('./pages/for-physicians/for-physicians.module').then(m => m.ForPhysiciansModule),
  },
  {
    path: 'genetics',
    loadChildren: () => import('./pages/genetics/genetics.module').then(m => m.GeneticsModule),
  },
  {
    path: 'demo-viewer/:id',
    loadChildren: () => import('./pages/demo-viewer/demo-viewer.module').then(m => m.DemoViewerModule),
  },
  {
    path: 'get-genetics-guide',
    loadChildren: () => import('./pages/get-genetics-guide/get-genetics-guide.module').then(m => m.GetGeneticsGuideModule),
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/brainkey-tutorial/brainkey-tutorial.module').then(m => m.BrainkeyTutorialModule),
  },
  {
    path: 'centrelake',
    loadChildren: () => import('./pages/centre-lake/centre-lake.module').then(m => m.CentreLakeModule),
  },
  {
    path: 'patient-records-upload/:mriRecordsRequestId',
    loadChildren: () => import('./old-modules/mri-records-upload/mri-records-upload.module').then(m => m.MriRecordsUploadModule),
  },
  {
    path: 'upload-mri',
    loadChildren: () => import('./pages/upload-mri/upload-mri.module').then(m => m.UploadMriModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'schedule-mri',
    loadChildren: () => import('./pages/schedule-mri/schedule-mri.module').then(m => m.ScheduleMriModule),
  },
  {
    path: '**',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
    enableTracing: false,
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
